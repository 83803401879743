import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils";
import Seo from "@components/seo";
import Layout from "@layout";
import Header from "@layout/header/layout-02";
import Footer from "@layout/footer/layout-01";
import PageHeader from "@containers/page-header/layout-02";
import ContactArea from "@containers/contact/layout-02";
import consulting from "../../assets/images/services/consulting.avif"

const ItSolutionsPage = ({ pageContext, location, data }) => {
    const content = normalizedData(data?.page?.content || []);
    const globalContent = normalizedData(data?.allGeneral.nodes || []);

    return (
        <Layout location={location}>
            <Seo titleTemplate="TEQT" title="SaaS Consulting Services in India"
                description="Within a span of six years, twenty or more software as a service (SaaS) projects were successfully delivered, with a total value of ten million bucks." />
            <Header
                data={{
                    ...globalContent["header"],
                    ...globalContent["menu"],
                    socials: data.site.siteMetadata.socials,
                }}
            />
            <main className="site-wrapper-reveal">
                <PageHeader
                    pageContext={pageContext}
                    location={location}
                    title="IT outsourcing company"
                />
                <div className="service-container">
                    <div className="left-sec">
                        <img src={consulting} alt="Custom Software Development" className="image" />

                    </div>
                    <div className="right-sec">
                        <h1>Saas Consulting</h1>
                        <p className="mt-2">
                            Our SaaS consulting services are designed to help businesses leverage the power of Software as a Service (SaaS) solutions to drive growth, streamline operations, and improve efficiency. Whether you're a startup looking to build a new SaaS product or an established company seeking to optimize your existing SaaS infrastructure, our team of experts is here to guide you every step of the way.
                        </p>
                    </div>
                </div>

                <div className="service-sub-container">
                    <ul>
                        <li className="mt-2"><strong>Discovery:</strong> We start by conducting a thorough analysis of your business objectives, target market, and existing technology infrastructure.</li>
                        <li className="mt-2"><strong>Strategy Development:</strong> Based on our findings, we work with you to develop a tailored SaaS strategy that aligns with your goals and addresses your unique challenges.</li>
                        <li className="mt-2"><strong>Implementation:</strong> Our team assists you in implementing the chosen SaaS solutions, ensuring seamless integration with your existing systems and processes.</li>
                        <li className="mt-2"><strong>Optimization:</strong> We continuously monitor and optimize your SaaS applications to maximize performance, scalability, and user satisfaction.</li>
                        <li className="mt-2"><strong>Training and Support:</strong> We provide comprehensive training and ongoing support to ensure that your team is equipped to effectively utilize and manage the SaaS solutions.</li>
                    </ul>
                    <h3 className="mt-4">Why Choose Us?</h3>
                    <p className="mt-2">
                        With years of experience in the SaaS industry, our consultants have a proven track record of helping businesses successfully navigate the complexities of SaaS adoption and implementation. Whether you're looking to launch a new SaaS product or optimize your existing SaaS infrastructure, we have the expertise and resources to help you achieve your goals.
                    </p>
                </div>
                <ContactArea data={content["contact-section"]} />
            </main>
            <Footer data={{ ...data.site.siteMetadata }} />
        </Layout>
    );
};

export const query = graphql`
    query SaasConsultingPageQuery {
        allGeneral {
            nodes {
                section
                ...HeaderTwo
            }
        }
        site {
            ...Site
        }
        page(title: { eq: "it-solutions" }, pageType: { eq: "innerpage" }) {
            content {
                ...PageContent
            }
        }
        allItSolution {
            nodes {
                ...ItSolutionThree
            }
        }
    }
`;

ItSolutionsPage.propTypes = {
    pageContext: PropTypes.shape({}),
    location: PropTypes.shape({}),
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        site: PropTypes.shape({
            siteMetadata: PropTypes.shape({
                contact: PropTypes.shape({}),
            }),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allItSolution: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export default ItSolutionsPage;
